import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'

import pic01 from '../../assets/images/xr/virtual-reality-jonathan-agoot.jpg'
import pic02 from '../../assets/images/xr/virtual-reality-american-association-of-immunologists.jpg'
import pic03 from '../../assets/images/xr/virtual-reality-american-society-for-cell-biology.jpg'
import pic04 from '../../assets/images/xr/virtual-reality-american-society-for-cytotechnology.jpg'
import pic05 from '../../assets/images/xr/virtual-reality-cambridge-health-institute-peptalk-the-protein-science-week.jpg'
import pic06 from '../../assets/images/xr/virtual-reality-demos-in-warehouse.jpg'
import pic07 from '../../assets/images/xr/virtual-reality-development-tijuana-mexico.jpg'
import pic08 from '../../assets/images/xr/virtual-reality-peace-science-society.jpg'
import pic09 from '../../assets/images/xr/virtual-reality-san-diego-stem-festival.jpg'
import pic10 from '../../assets/images/xr/virtual-reality-society-for-neuroscience.jpg'
import pic11 from '../../assets/images/xr/virtual-reality-cell-culture-.jpg'
import pic12 from '../../assets/images/xr/virtual-reality-scooter.jpg'
import pic13 from '../../assets/images/xr/virtual-reality-stem-cell-reprogramming-lenti-retrovirus.jpg'
import pic14 from '../../assets/images/xr/virtual-reality-accomplishments-impact.jpg'

const Generic = (props) => (
	<Layout>
		<div>
			<Helmet>
				<title>Virtual Reality (VR) | Jonathan Agoot</title>
				<meta name="description" content="Jonathan Agoot - Virtual Reality" />
			</Helmet>
			<div id="main" className="alt">
				<section id="one" className="tiles">
					<div className="inner">
						<header className="major">
							<h1>Virtual Reality</h1>
						</header>
						<hr />
						<header className="major">
							<h3>Preface</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>It was August 2016 at Thermo Fisher Scientific when interests and discussions on virtual reality (VR) and augmented reality (AR) started to circulate. From scientists to business persons, everyone started to ponder and inquire about the two nascent technologies. This was also the year that Facebook’s Oculus Rift, HTC’s Vive, and Microsoft’s HoloLens were released. Brave early adopters with deep pockets preordered their devices to secure a guarantee they would receive their devices on time and begin a new digital adventure.</p>
								<p>But what dominated the conversions were of skepticism and speculation; debates and assumptions haphazardly tossed into meetings. This was where the opportunity to put to rest the uncertainty by a band of internal entrepreneurs for the benefit of both internal stakeholders and customers.</p>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic01} alt="Jonathan Agoot - Extended Reality (XR)" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Find Early Adopters</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>I brought in my own VR setup from home and started doing demos in the warehouse which steadily grew into a demo team of over 20+. We pushed this cart all over the campus, taking over empty offices and scheduling VR intake meetings with business partners and scientists. Gradually, we started receiving greater interest and strategic allocations for funding to start production of VR experiences through vendors.</p>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic06} alt="Jonathan Agoot - Finding Early Adopters with Virtual Reality" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Crawling Before Walking</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>In December 2016, we attended the American Society for Cell Biology (ASCB) conference in San Francisco. We demoed the “VR tour inside the cell model” which earned praise and excitement with scientists of all ages. Thermo Fisher was the only VR exhibitor. We were not only getting more attendance than our competitors but customers were staying at our booth much longer and having deeper conversations with our representatives simply because VR helped open and stimulate conversations.</p>
								<p>Total investment: $5000 of travel expenses and shipping.</p>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic03} alt="Jonathan Agoot - American Society for Cell Biology Setup" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>Repeatable and Scalable Results</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>After our first virtual reality gig at the American Society for Cell Biology, word had spread about the benefits of being “VR enabled”. Business partners wanted VR for their conferences and meetings with customers. Also, our Corporate Social Responsibility department wanted to showcase a safe and educational VR experience for kids as well.</p>
								<p>We had invested into a VR laptop so we can start testing the reliability of the hardware and software for future scaling opportunities. Plus, it was much lighter than a hauling around a full computer tower and flat panel LCD monitor.</p>
							</div>
							<div className="col-6">
								<h4>Partial Listing of Conferences Exhibited:</h4>
								<ul>
									<li>ISSCR: International Society of Stem Cell Research</li>
									<li>SFN: Society for Neuroscience</li>
									<li>AAI: American Association of Immunologists</li>
									<li>ASCB: American Society For Cell Biology</li>
									<li>ASCT: American Society For Cytotechnology</li>
									<li>CHI: Cambridge Health Institute Peptalk the Protein Science Week</li>
									<li>Penn State Peace Science Society</li>
									<li>San Diego STEM (Science, Technology, Engineering, and Math) Festival</li>
									<li>The San Diego New Children's Museum</li>
								</ul>
							</div>
						</div>
						<div className="grid-wrapper">
							<div className="col-4">
								<span className="image fit"><img src={pic10} alt="Jonathan Agoot - SFN: Society for Neuroscience" /></span>
							</div>
							<div className="col-4">
								<span className="image fit"><img src={pic02} alt="Jonathan Agoot - AAI: American Association of Immunologists" /></span>
							</div>
							<div className="col-4">
								<span className="image fit"><img src={pic04} alt="Jonathan Agoot - ASCT: American Society for Cytotechnology" /></span>
							</div>
							<div className="col-4">
								<span className="image fit"><img src={pic08} alt="Jonathan Agoot - Penn State Peace Science Society" /></span>
							</div>
							<div className="col-4">
								<span className="image fit"><img src={pic09} alt="Jonathan Agoot - San Diego STEM (Science, Technology, Engineering, and Math) Festival" /></span>
							</div>
							<div className="col-4">
								<span className="image fit"><img src={pic05} alt="Jonathan Agoot - CHI: Cambridge Health Institute Peptalk the Protein Science Week" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>The First Three VR Experiences</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-4">
								<h5>Virtual Reality<br />Cell Culture Transfection Game</h5>
								<span className="image fit"><img src={pic11} alt="Jonathan Agoot - Cell Culture Transfection Game" /></span>
								<p>How do you use Virtual Reality to help customers achieve the optimal protein yield using the Gibco ExpiCHO expression system?</p>
								<p>You find their pain points and create a fun, futuristic, immersive, Virtual Reality game to emphasis protocol best practices. "ExpiCHO-down," will challenge users to complete three steps of "passaging cells, transfecting cells, and shaking cells," to achieve the optimal yield of 3 g/L. The VR game will capture points on each step based on task performance.</p>
								<p>The goal is to have customers compete against their colleagues to get the highest score/protein yield. The VR game will give them a memorable experience that can impact their daily life.</p>
							</div>
							<div className="col-4">
								<h5>Virtual Reality<br />Scooters for Brand Awareness</h5>
								<span className="image fit"><img src={pic12} alt="Jonathan Agoot - Scooters for Brand Awareness" /></span>
								<p>This VR experience is based off the Immunoassay “scooter brand awareness campaign”. You choose one of three scooters and take a fun ride through a futuristic life science city. Each scooter represents one of the three product lines: ProcartaPlex, ELISAs and ProQuantum high-sensitivity immunoassays.</p>
							</div>
							<div className="col-4">
								<h5>Virtual Reality<br />Stem Cell Reprogramming Demonstration</h5>
								<span className="image fit"><img src={pic13} alt="Jonathan Agoot - Stem Cell Reprogramming Demonstration" /></span>
								<p>This VR experience is educational and feature Stem Cell Reprogramming methodologies along with discussion on cell culturing and differentiation. You are guided at a cellular level and see the process from the petri dish level to inside the cell.</p>
							</div>
						</div>
					</div>
					<hr />
					<div className="inner">
						<header className="major">
							<h3>Accomplishments and Impact</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>Here's what we were able to accomplish within a one year timeline:</p>
								<ul>
									<li><b>15</b> virtual reality experiences developed company wide</li>
									<li>Grew a team of over <b>20</b> early adopters to spread across the enterprise</li>
									<li>Over <b>18</b> global conferences attended with teams that were virtual reality enabled</li>
									<li>Delivered over <b>7200</b> virtual reality demonstrations</li>
									<li>Incresead the average in-booth retention of our conference attendees by <b>25%</b></li>
									<li>Increased the average word-of-mouth referral per conference on an average of <b>15</b> people</li>
								</ul>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic14} alt="Jonathan Agoot - Virtual Reality Accomplishments and Impact" /></span>
							</div>
						</div>
						<hr />
						<header className="major">
							<h3>One Year Later</h3>
						</header>
						<div className="grid-wrapper">
							<div className="col-6">
								<p>What started in the warehouse became more than we've dreamed of.</p>
								<p>The demand for virtual reality and augmented reality development has been rising which led to the formation of standing up a development team at Thermo Fisher Scientific's Tijuana campus in Mexico.</p>
								<p>With proper funding, interest, and a fully built out network of business partners, Thermo Fisher can now focus on mid to long term strategies to deliver world class virtual reality and augmented reality experiences.</p>
							</div>
							<div className="col-6">
								<span className="image fit"><img src={pic07} alt="Jonathan Agoot - Thermo Fisher Scientific Tijuana, Mexico" /></span>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</Layout >
)

export default Generic
